<template>
  <div class="works">
    <LeftMenu current="Accountandsecurity" />
    <div class="main">
      <div class="headtitle">
        <p>账户与安全</p>
      </div>
      <ul class="ulform">
        <li>
          <div class="left">登入手机号</div>
          <div class="right">{{ valphone }}</div>
        </li>
        <li>
          <div class="left">认证信息</div>
          <div v-if="identity == 0" class="right">
            {{ data.name }}
            <div class="authen" alt="">
              <img src="../assets/examine/authen.png" alt="" /><i>{{
                results
              }}</i>
            </div>
          </div>
          <div v-if="identity == 1" class="right">
            {{ attestation.name }}
            <div class="authen">
              <img src="../assets/examine/authen.png" alt="" /><i>{{results}}</i>
            </div>
          </div>
        </li>
        <li>
          <div class="left">登录密码</div>
          <div class="right"><span @click="fn">修改</span></div>
        </li>
        <li>
          <div class="left">银行卡</div>
          <div class="right">
            {{ bankcard }}
            <span class="spanstyle" @click="setbank">{{ title }}</span>
          </div>
        </li>
        <li>
          <div class="left">钱包密码</div>
          <div class="right"><span @click="fn1">修改</span></div>
        </li>
      </ul>
    </div>
    <passwordBox ref="tablebox"></passwordBox>
    <PurPasswordBox ref="istable"></PurPasswordBox>
  </div>
</template>

<script>
import LeftMenu from '../components/LeftMenu.vue'
import passwordBox from '../components/PasswordBox.vue'
import PurPasswordBox from '../components/PurPasswordBox.vue'

export default {
  components: {
    LeftMenu,
    passwordBox,
    PurPasswordBox,
  },
  data () {
    return {
      flang: false,
      valphone: '',
      bankcard: "",
      alterReve: true,
      attestation: '',
      // 个人信息
      data: {},
      identity: '',
      // 银行卡提示
      title: '',
      // 认证结果
      results: ''
    }
  },
  mounted () {
    this.$post('/api/user/userMsg').then((res) => {
      let arr = res.data.mobile.split('')
      arr.splice(3, 4, "****")
      this.valphone = arr.join('')
      this.attestation = res.data.userCompanyVo
      this.data = res.data
      this.results = this.credentials(res.data)
      //获取个人身份
      this.identity = res.data.webLoginType
      if (this.attestation == null) {
        this.attestation = {}
      }
      // 获取最新状态
      this.$store.commit('UMSG', res.data)
    })
    this.$post('/api/wallet/myBankMsg').then((res) => {
      if (res.data == null) {
        this.bankcard = '未绑定'
        this.alterReve = true
        this.title = '新增'
      } else {
        if (res.data.status == 0) {
          this.title = '审核中'
        } else if (
          res.data.status == 2
        ) {
          this.title = '审核失败'
        } else {
          this.title = '修改'
        }
        let bacd = res.data.bankCard.substring(res.data.bankCard.length - 4, res.data.bankCard.length)
        this.bankcard = `${res.data.bankName}(${bacd})`
      }
    })

  },

  methods: {
    fn () {
      this.$refs.tablebox.changePassword(this.valphone, this.data)
    },
    fn1 () {
      this.$refs.istable.changePassword(this.valphone, this.data)
    },
    setbank () {
      if (this.title !== '审核中') {
        this.$router.push('/setbankcard')
      }

    },
    credentials (val) {
      if (val.webLoginType == 0) {
        if (val.verifiedStatus === 1) {
          return '审核中'
        } else if (
          val.verifiedStatus === 2
        ) {
          return '认证失败'
        } else if (val.verifiedStatus === 3) {
          return '已认证'
        }
      } else {
        if (val.userCompanyVo.status === 0) {
          return '审核中'
        } else if (
          val.userCompanyVo.status === 1
        ) {
          return '已认证'
        } else if (val.userCompanyVo.status === 2) {
          return '认证失败'
        } else {
          return ''
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import "../styles/initialization.scss";
@import "../styles/works.scss";
.main {
  .headtitle {
    margin: 0px auto;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 14px;
    p {
      box-sizing: border-box;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .ulform {
    overflow: hidden;
    li {
      display: flex;
      margin-top: 40px;
    }
    .left {
      width: 100px;
      height: 18px;
      font-size: 14px;
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 500;
      color: #333333;
      line-height: 18px;
    }
    .right {
      height: 18px;
      font-size: 14px;
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 400;
      color: #333333;
      line-height: 18px;
      span {
        color: #ff004d;
        cursor: pointer;
      }
      .spanstyle {
        margin-left: 10px;
      }
      .authen {
        display:inline-flex;
        align-items: center;
        padding-right: 5px;
        height: 18px;
        border-radius: 9px;
        background-color: #ff004d;
        margin-left: 5px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        img {
          margin-left: 4px;
          margin-right: 3px;
          width:12px;
          height:12px;
        //   vertical-align: middle;
        }
        i {
          font-style: normal;
        //   vertical-align: middle;
        }
      }
    }
  }
}
</style>
